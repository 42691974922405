import { useStore } from "@nanostores/solid";
import { createSignal, lazy, Show, VoidComponent } from "solid-js";
import { authUserId } from "../../auth/authStore";
import { Contracts, formatTokenWad, Token, Tokens } from "../../web3/tokens";
import { icon } from "../icons/Icons";
import { createQuery, CreateQueryResult, QueryClientProvider } from "@tanstack/solid-query";
import { queryClient } from "../../api-client";
import { erc20BalanceABI } from "../../web3/balance";
import { requireAccount } from "../../web3/PoolContract";
import { isStale, loadFromCache, persistToCache } from "./cache";
import { ModalLoader } from "../ui/ModalLoader";
import { Portal } from "solid-js/web";
import { openConnectWalletModal } from "../../web3/accountStore";
import "../contest/shadows.scss";

import { readContracts } from '@wagmi/core'
import { PoolContractAbi } from "../../web3/abi";


const WithdrawModal = lazy(() => import("../wallet/WithdrawModal"));

const BalanceView: VoidComponent = () => {
    return <QueryClientProvider client={queryClient}>
        <BalanceActual token="dai" />
    </QueryClientProvider>
}

const BalanceActual: VoidComponent<{ token: Token }> = (props) => {
    const token = Tokens[props.token];
    const Logo = icon(token.symbol);

    const $authUserId = useStore(authUserId);
    const isAuthenticated = () => !!$authUserId();

    type cacheData = {
        balances: [bigint, bigint],
        userId: string
    }

    type QueryResult = {
        balances: [bigint, bigint],
        fromCache: boolean
    }
    const cachedResult = loadFromCache()

    const query: CreateQueryResult<QueryResult, unknown> = createQuery(() => ['balances', $authUserId()],
        async () => {
            if (!!cachedResult && !isStale(cachedResult)) {
                const data: cacheData = cachedResult.data
                if (data.userId == $authUserId()) {
                    return {
                        fromCache: true,
                        balances: data.balances,
                    }
                }
            }

            const result = await readContracts({
                contracts: [
                    {
                        address: token.address,
                        abi: erc20BalanceABI,
                        functionName: 'balanceOf',
                        args: [requireAccount()],
                    },
                    {
                        address: Contracts.ldPool,
                        abi: PoolContractAbi,
                        functionName: 'prizeBalanceOf',
                        args: [requireAccount(), token.address],
                    },
                    {
                        address: Contracts.ldPool,
                        abi: PoolContractAbi,
                        functionName: 'refundBalanceOf',
                        args: [requireAccount(), token.address],
                    },
                ],
                allowFailure: false
            })

            return {
                fromCache: false,
                balances: [result[0], result[1]+result[2]],
            }
        },
        {
            refetchOnWindowFocus: false,
            staleTime: 120_000,  //2min
            refetchInterval: 60_000,
            get enabled() {
                return isAuthenticated()
            },
            onSuccess(data) {
                if (!data.fromCache) {
                    persistToCache({
                        userId: $authUserId(),
                        balances: data.balances,
                    })
                }
            },
            retry: 1,
        })

    const [showWithdrawModal, setShowWithdrawModal] = createSignal(false)
    const hideModalAndRefresh = () => {
        setShowWithdrawModal(false)
        query.refetch()
    }

    const openWithdrawalModal = () => {
        if (isAuthenticated()) {
            setShowWithdrawModal(true)
        } else {
            openConnectWalletModal()
        }
    }

    return <div class="">
        <div class="flex flex-col gap-2 bg-gray-800 rounded-md p-2">
            <div class="ps-0.5 flex flex-row gap-1 text-sm font-medium text-gray-600 dark:text-gray-300/80">
                <span class="flex items-center tracking-tighter">
                    <Logo height={"1em"} width={"1em"} class="inline" />
                    <p class="font-mono">{token.name}</p>
                </span>
                Balance
            </div>

            <div class="grid grid-cols-2 gap-1 text-xs font-medium">
                <div class="grow flex flex-col items-stretch rounded-es-md rounded-ee-md overflow-clip bg-gray-700/70">
                    <p class="py-2 text-center link-hover hover:cursor-pointer text-sm tracking-tighter" onClick={openWithdrawalModal}>
                        <Show when={query.isSuccess && query.data.balances.length == 2} fallback={0}>
                            <span class="text-[11px]"><Logo /></span> {formatTokenWad(props.token, query.data!.balances[1], 2)}
                        </Show>
                    </p>
                    <p class="text-gray-500 dark:text-gray-900 bg-gray-300 py-0.5 text-center tracking-wide lg:text-xs">Winnings</p>
                </div>

                <div class="grow flex flex-col items-stretch rounded-es-md rounded-ee-md overflow-clip bg-gray-700/70">
                    <p class="py-2 text-center text-sm tracking-tighter">
                        <Show when={query.isSuccess && query.data.balances.length == 2} fallback={0}>
                            <span class="text-[11px]"><Logo /> </span> {formatTokenWad(props.token, query.data!.balances[0], 2)}
                        </Show>
                    </p>
                    <p class="text-gray-500 dark:text-gray-900 bg-gray-300 py-0.5 text-center tracking-wide lg:text-xs">Wallet</p>
                </div>
            </div>

            <button class="text-xs rounded-md border border-indigo-500 hover:bg-indigo-600 py-[6px] font-semibold
             text-indigo-400 hover:text-indigo-200 bg-indigo-500/10" onClick={openWithdrawalModal} title="withdraw">
                Withdraw Winnings
            </button>

        </div>
        <Portal mount={document.body}>
            <ModalLoader
                component={WithdrawModal}
                show={showWithdrawModal}
                hide={hideModalAndRefresh}
                onComplete={hideModalAndRefresh}
            />
        </Portal>
    </div>
}


export default BalanceView;