const cacheKey = "ld.cache.balances.v2"
const cacheDuration = 60_000;   //millseconds

type CacheEntry = {
    data: any,
    timestamp: number
}

export function loadFromCache() {
    let cachedResult: CacheEntry | undefined
    const entry = localStorage.getItem(cacheKey)
    if (!!entry) {
        try {
            cachedResult = JSON.parse(entry, (key: string, value: any) => {
                if (key == "balances" && Array.isArray(value)) {
                    return (value as [string]).map(v => BigInt(v))
                }
                return value
            })
        } catch (error) {
            console.error({ error })
        }
    }

    return cachedResult;
}

export function isStale(data?: CacheEntry) {
    return !data || (data.timestamp + cacheDuration) < Date.now()
}

export function persistToCache(data: any) {
    const cachedResult: CacheEntry = {
        data: data,
        timestamp: Date.now(),
    }

    const replacer = (key: string, value: any) =>
        typeof value === "bigint" ? value.toString() : value;

    localStorage.setItem(cacheKey, JSON.stringify(cachedResult, replacer))
}